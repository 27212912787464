// Available color palettes in Biotablero with a name assigned
export default {
  rainbowFc: [
    "#c3374d",
    "#ea495f",
    "#ea5948",
    "#d5753d",
    "#eabc47",
    "#b1b559",
    "#a4c051",
    "#81bb47",
    "#51b4c1",
    "#4a8fb8",
    "#5564a4",
    "#6256a5",
    "#7b56a5",
  ],
  greenBiomes: [
    "#d49242",
    "#e9c948",
    "#b3b638",
    "#acbf3b",
    "#92ba3a",
    "#70b438",
    "#5f8f2c",
    "#667521",
    "#75680f",
    "#7b6126",
  ],
  bioticReg: [
    "#003d59",
    "#5a1d44",
    "#902130",
    "#6d819c",
    "#db9d6b",
    "#fb9334",
    "#fe6625",
    "#ab5727",
    "#44857d",
    "#167070",
  ],
  coverage: ["#164f74", "#fcbd64", "#CD7165", "#c8bda7"],
  pa: ["#b9c9cf", "#484852", "#8b8d45", "#d9b86b", "#524320", "#ab4a30"],
  seBlue: ["rgba(255,255,255,0)", "#51b4c1"],
  shortFC: ["#ea495f", "#eabc47", "#51b4c1"],
  hfTimeline: [
    "#3d3c48",
    "#3d3c48",
    "#bba2ff",
    "#916cf5",
    "#afcc83",
    "#628b41",
    "#95b4db",
    "#2964ac",
  ],
  hfCurrent: ["#3fbf9f", "#d5a529", "#e66c29", "#cf324e"],
  hfPersistence: ["#61a5a2", "#caaf71", "#e0526b"],
  paramo: ["#b7a0f2"],
  dryForest: ["#8cb64c"],
  wetland: ["#5594d9"],
  sePAConn: ["#120000"],
  forestLP: ["#92ab58", "#c65453", "#268a9a", "#c5b599"],
  SciHf: ["#003d59", "#0d6870", "#60bbd4", "#f1bd7f", "#da8634", "#ab5727"],
  currentPAConn: ["#92ab85", "#a57660", "#b9c9cf"],
  dpc: ["#c3374d", "#ea5948", "#eabc47", "#a4c051", "#51b4c1"],
  timelinePAConn: ["#00A292", "#008273", "#92ab85", "#768a6b"],
  richnessNos: [
    "#fc6467",
    "#9ba211",
    "#E07979",
    "#A4CF32",
    "#F0314E",
    "#88B22A",
    "#B31012",
    "#366B27",
    "#fcc76f",
    "#fba76d",
    "#ffb56c",
    "#007d8f",
  ],
  richnessGaps: [
    "#1348a6",
    "#1997f2",
    "#113779",
    "#a9b510",
    "#59651f",
    "#ae2130",
    "#551459",
    "#fcbd64",
    "#2080a2",
    "#eba62a",
    "#ec5e41",
  ],
  caTargets: ["#e25648", "#ee8531", "#fdc031", "#b572ad", "#59c9eb"],
  functionalDryForestValues: ["#b74777", "#fba76d"],
  functionalDFFeatureLA: ["#3d4726", "#5bb58b", "#1d754c", "#4f861d"],
  functionalDFFeatureLN: ["#3d4726", "#5bb58b", "#1d754c", "#6d9a1e"],
  functionalDFFeaturePH: ["#3d4726", "#5bb58b", "#1d754c", "#a2aa16"],
  functionalDFFeatureSLA: ["#3d4726", "#5bb58b", "#1d754c", "#e5ba11"],
  functionalDFFeatureSSD: ["#3d4726", "#5bb58b", "#1d754c", "#b5924e"],
  functionalDFFeatureSM: ["#3d4726", "#5bb58b", "#1d754c", "#916a31"],
  polygon: ["#2a363b"],
  border: ["#fc6467"],
  default: ["#b2bdc2"],
};
